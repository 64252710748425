.vrw-image-view
	position: fixed
	top: 0
	right: 0
	left: 0
	bottom: 0
	background: #000000 !important
	z-index: 100
	display: flex
	justify-content: center
	align-items: center
	max-height: 100vh

	.close-button
		z-index: 101
		position: absolute
		right: 8px
		top: 8px
		color: white
		background: #00000040
		&:hover
			background: #00000080

	.react-transform-component
		overflow: visible
		//max-height: 100%

	//.react-transform-element
	//	max-height: 100%

	.image-view-content
		width: auto
		max-width: 100%
		max-height: 100vh
		object-fit: contain

