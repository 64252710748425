@import "~react-image-gallery/styles/scss/image-gallery.scss"
@import 'react-phone-input'
@import 'pages'
@import 'modals'

@mixin vendor-prefix($name, $value)
  @each $vendor in ('-webkit-', '-moz-', '-ms-', '-o-', '')
    #{$vendor}#{$name}: #{$value}

html
  margin: 0
  padding: 0
  min-height: 100vh

body
  margin: 0
  min-height: 100vh
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif !important
  -webkit-font-smoothing: antialiased
  -moz-osx-font-smoothing: grayscale
  -webkit-background-size: cover
  -moz-background-size: cover
  -o-background-size: cover
  letter-spacing: normal

main
  background-color: #00000000

pre
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif !important

code
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace

.linkInvisible
  @include vendor-prefix('user-select', none)
  text-decoration: none
  color: inherit


.MuiFormLabel-root
  text-transform: capitalize

.MuiButton-label
  text-transform: capitalize
  font-weight: 400


.image-gallery-image
  height: 68vh

  @media (max-width: $ig-xsmall-screen)
    height: 40vh

.image-gallery-slide
  filter: opacity(30%)
  &.center
    filter: opacity(100%)

.image-gallery-thumbnail
  .image-gallery-thumbnail-inner
    height: calc(109px * 9 / 16)
    overflow: hidden

  @media (max-width: $ig-small-screen)
    .image-gallery-thumbnail-inner
      height: calc(89px * 9 / 16)

  .image-gallery-thumbnail-image
    height: inherit
    width: auto
