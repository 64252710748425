.itemHeader
  display: flex
  flex-direction: row
  padding: 8px
  flex-shrink: 0
  line-height: normal

  .iconContainer, .iconContainer1
    //background: #13ffff
    width: 34px
    margin-right: 4px
    display: flex
    flex-direction: column
    align-items: center
    justify-content: center

  .iconContainer2
    //background: #13ffff
    width: 60px
    margin-right: 4px
    display: flex
    flex-direction: column
    align-items: center
    justify-content: center

  .leftContainer
    flex-grow: 1
    display: flex
    flex-direction: column
    align-items: flex-start
    justify-content: center
    overflow: hidden

    .title, .title1
      font-size: 13px
      font-weight: 600
      padding: 0
      margin: 0
      white-space: nowrap
      overflow: hidden
      text-overflow: ellipsis
      width: 100%

    .title2
      font-size: 18px
      font-weight: 600
      padding: 0
      margin: 0

    .subtitle, .subtitle1
      font-size: 13px
      font-weight: 400
      padding: 0
      margin: 0
      color: #808080
      white-space: nowrap
      overflow: hidden
      text-overflow: ellipsis
      width: 100%

    .subtitle2
      font-size: 18px
      font-weight: 400
      padding: 0
      margin: 0
      color: #808080

  .rightContainer
    display: flex
    align-items: center

    .title
      font-weight: 600
      margin: 0 8px
      padding: 0
      white-space: nowrap
