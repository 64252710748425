.appContainer
	padding-top: 16px
	padding-bottom: 56px + 16px + 16px

.visitorRouteContainer
	padding-top: 16px
	padding-bottom: 56px + 16px + 16px
	min-height: 100vh
	display: flex !important
	align-items: center !important

