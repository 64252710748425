.houseInspector
  .headers
    margin-bottom: 8px

  .details
    margin-bottom: 8px

  .inspector-detail
    padding: 5px 22px
    display: grid
    grid-auto-flow: column
    grid-template-columns: [first] 24px [one] auto [two] 50%

    .label
      align-self: center
      padding: 0 10px
      line-height: 26px

    .value
      align-self: center
