#splashScreen
  //background: #d6d6d6
  background: none
  position: fixed
  top: 0
  left: 0
  right: 0
  bottom: 0
  min-width: 100vw
  min-height: 100vh
  z-index: 100000
  pointer-events: none

  &.hide
    animation-duration: 0.66s
    animation-fill-mode: forwards
    animation-name: fadeOut

    @keyframes fadeOut
      from
        opacity: 85%
      to
        opacity: 0

  #splashScreenImage
    height: auto
    width: auto
    padding-bottom: 128px
    animation-duration: 1.33s
    animation-fill-mode: forwards
    animation-name: fadeIn

    @keyframes fadeIn
      from
        opacity: 0
        transform: scale(0.6)
      to
        opacity: 85%
        transform: scale(0.99)
