/* 16:9 => height 56.25% of picture width */

.mediaContainer
  background: #d6d6d6
  position: relative
  display: block
  width: 100%
  height: auto
  padding: 56.25% 0 0 0

  .image
    display: block
    object-fit: cover
    margin: auto
    width: 100%
    max-width: 100%
    max-height: 100%
    position: absolute
    overflow: hidden
    top: 0
    left: 0
    right: 0
    bottom: 0

  &.wide
    padding: 50vh 0 0 0

    .image
      width: auto
      max-width: 100%
      max-height: 100%
      overflow: hidden
      //height: 100%
      //max-width: 512px
      //max-height: 56.25%
      //max-width: 200%
      //width: auto
      //max-width: 200%

  &.small
    border: 2px solid red
