.itemHeadLine
  display: flex
  flex-direction: row
  padding: 0 0 8px 0
  flex-shrink: 0
  line-height: normal

  .iconContainer
    width: 34px
    margin-right: 4px
    display: flex
    align-items: center
    justify-content: center

  .titleContainer
    flex-grow: 1
    display: flex
    flex-direction: row
    align-items: center
    justify-content: flex-start
    flex-wrap: wrap
