.houseInspector
  .headers
    margin-bottom: 0px

  .details
    padding: 0px 0px
    display: flex
    flex-direction: row
    flex-wrap: wrap
    width: 100%
    margin-bottom: 0px

    > *
      flex-grow: 1
      margin: 2px

  .inspector-detail
    padding: 5px 22px
    display: grid
    grid-auto-flow: column
    grid-template-columns: [first] 24px [one] auto [two] 50%

    .label
      align-self: center
      padding: 0 10px
      line-height: 26px

    .value
      align-self: center

    .MuiIcon-root
      font-size: 26px
