.drillCard
  position: relative
  max-width: 480px - 32px
  margin: auto

  //&:hover
  //  box-shadow: #00000080 0 4px 16px

  .details
    padding: 4px
    display: flex
    flex-direction: row
    flex-wrap: wrap
    width: 100%

    > *
      flex-grow: 1
      margin: 2px

    //.detail-surface
    //  margin-right: 4px
    //  //display: inline-flex
    //  //  flex-shrink: 0
    //  //flex-grow: 1

    //.detail-rooms
    //  display: inline-flex
    //  flex-grow: 0
    //  flex-shrink: 0

  .bookmarkButton
    position: absolute
    right: 8px
    top: 8px
    padding: 6px
    background: #32323240
    color: #ffffff80

    &.active
      color: #efdf23

    .MuiIcon-root
      font-size: 26px