.modal
  display: flex
  flex-direction: column
  min-height: 100%
  position: relative

  .modalBar
    margin: 0
    display: flex
    flex-direction: row
    justify-content: space-between
    align-items: center
    padding: 8px 16px
    position: relative

    .modalTitle
      position: absolute
      left: 0
      right: 0
      text-align: center
      pointer-events: none
      text-transform: capitalize

  .modalBody
    flex: 1

    &.gridPadding
      padding: 8px

    .content
      min-height: 60%
      padding: 16px 16px 16px 16px


  .footer
    padding: 0 16px 16px 16px
    position: relative
    bottom: 0
    left: 0
    right: 0

    .button
      display: block
      margin: auto
      min-width: 33%
